import { IntlProvider } from 'react-intl';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ignoreMissingTranslationWarnings, useLanguageStore } from '@trustyou/shared';
import { messages as surveyMessages } from '@trustyou/survey-manager';

import { routes } from './routes';

import { useThemeStore } from './store';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // onError: (error) => toast.error(`Something went wrong: ${error.message}`),
    onError: (error) => console.error(`Something went wrong: ${error.message}`),
  }),
});
const router = createBrowserRouter(routes, {
  basename: `/${process.env.REACT_APP_ROUTER_BASENAME}`,
});

export default function App() {
  const { locale } = useLanguageStore();
  const surveyTheme = useThemeStore((state) => state.theme);

  return (
    <IntlProvider
      onError={ignoreMissingTranslationWarnings}
      locale={locale}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      messages={surveyMessages[locale as keyof typeof surveyMessages]}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={surveyTheme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </QueryClientProvider>
    </IntlProvider>
  );
}
