import { isNumberArray, isStringArray } from '@trustyou/shared';
import { isBoolean, isNumber, isString } from 'lodash';

import type {
  Email,
  Image,
  MaxLength,
  MaxValue,
  MinLength,
  MinValue,
  PageItemContent,
  PhoneNumber,
  Question,
  Section,
  Text,
  ThankyouMessage,
  WelcomeMessage,
} from '../client';
import type { Content, Validation } from '../types';

// Content
export const isSection = (content: Content | PageItemContent): content is Section =>
  content.type === 'section';
export const isQuestion = (content: Content): content is Question => content.type === 'question';
export const isText = (content: Content): content is Text => content.type === 'text';
export const isThankyouMessage = (content: Content): content is ThankyouMessage =>
  content.type === 'thankyou_message';
export const isWelcomeMessage = (content: Content): content is WelcomeMessage =>
  content.type === 'welcome_message';
export const isImage = (content: Content): content is Image => content.type === 'image';

// Validation
export const isMinLength = (validation: Validation): validation is MinLength =>
  validation.name === 'min_length';
export const isMaxLength = (validation: Validation): validation is MaxLength =>
  validation.name === 'max_length';
export const isMinValue = (validation: Validation): validation is MinValue =>
  validation.name === 'min_value';
export const isMaxValue = (validation: Validation): validation is MaxValue =>
  validation.name === 'max_value';
export const isEmail = (validation: Validation): validation is Email => validation.name === 'email';
export const isPhoneNumber = (validation: Validation): validation is PhoneNumber =>
  validation.name === 'phone_number';

// Conditional questions
export const isSingleSelect = (value: unknown): value is string | number | boolean =>
  isString(value) || isNumber(value) || isBoolean(value);
export const isMultipleSelect = (values: unknown[]): values is string[] | number[] =>
  isStringArray(values) || isNumberArray(values);
