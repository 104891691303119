import { v4 as uuid } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { EXPIRY_DAYS } from '../utils';

type OutboundIDStats = {
  outboundIDs: { [key: string]: { value: string; date: number } } | null;
  setOutboundID: (surveyId: string) => void;
  checkExpiration: (surveyId: string) => void;
};

const isExpired = (timestamp: number) => {
  const now = new Date().getTime();
  const expiryTime = EXPIRY_DAYS * 24 * 60 * 60 * 1000;
  return now - timestamp > expiryTime;
};

export const generateOutboundID = () => {
  const now = new Date().getTime();
  const generatedID = uuid();
  return { value: generatedID, date: now };
};

export const useOutboundIDStore = create<OutboundIDStats>()(
  persist(
    (set, get) => ({
      outboundIDs: {},
      setOutboundID: (surveyId: string) => {
        const newID = generateOutboundID();
        set((state) => ({
          outboundIDs: { ...state.outboundIDs, [surveyId]: newID },
        }));
        window.location.reload();
      },
      checkExpiration: (surveyId: string) => {
        const { outboundIDs } = get();
        if (outboundIDs && outboundIDs[surveyId]) {
          const { date } = outboundIDs[surveyId];
          if (isExpired(date)) {
            const newID = generateOutboundID();
            set((state) => ({
              outboundIDs: { ...state.outboundIDs, [surveyId]: newID },
            }));
            window.location.reload();
          }
        }
      },
    }),
    {
      name: 'outbound-ids',
    }
  )
);
