import type { Theme } from '@mui/material';
import { theme as trustYouTheme } from '@trustyou/ui';
import { create } from 'zustand';

export type ExtendedTheme = Theme & {
  extra?: {
    primaryColor?: string;
    buttonTextColor?: string;
    entityNameColor?: string;
    sectionTitleColor?: string;
    sectionTitleFontFamily?: string;
  };
};

type ThemeState = {
  theme: ExtendedTheme;
  setTheme: (theme: ExtendedTheme) => void;
};

export const useThemeStore = create<ThemeState>()((set) => ({
  theme: trustYouTheme,
  setTheme: (theme) => set(() => ({ theme })),
}));
