import { FormattedMessage } from 'react-intl';

import type { Theme } from '@mui/material';
import { isDarkColor } from '@trustyou/shared';
import { Box, Stack, TyLogo, Typography } from '@trustyou/ui';

import { MAX_CARD_WIDTH_PX } from '../../constants';

export type SurveyFooterProps = {
  entity: {
    id?: string;
    name?: string | null;
    brand?: string | null;
    continent?: string | null;
    country?: string | null;
    city?: string | null;
    address?: string | null;
    type?: string | null;
    state?: string | null;
    zip?: string | null;
  };
  theme?: Theme;
};

export function SurveyFooter({ entity, theme }: SurveyFooterProps) {
  const isDarkBG = isDarkColor(theme?.palette.background.default as string);
  const mainColor = isDarkBG
    ? theme?.palette.getContrastText(theme.palette.text.primary)
    : theme?.palette.text.primary;
  const secondaryColor = isDarkBG
    ? theme?.palette.getContrastText(theme.palette.text.secondary)
    : theme?.palette.text.secondary;

  return (
    <Box
      sx={(theme) => ({
        maxWidth: MAX_CARD_WIDTH_PX,
        paddingBlock: { xs: 2, sm: 3 },
        paddingInline: 2,
        display: 'flex',
        gap: 2,
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomWidth: 0,
        [theme.breakpoints.only('xs')]: {
          gap: 1,
          borderRadius: 0,
          borderInline: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      })}
    >
      <>
        <Stack sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
          <Typography variant="subtitle2" sx={{ color: mainColor }}>
            {entity.name}
          </Typography>
          {entity?.address && (
            <Typography variant="body2" sx={{ color: secondaryColor }}>
              {entity.address}, {entity.city} {entity.zip}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" sx={{ color: mainColor }}>
            <FormattedMessage id="survey.footer.by" defaultMessage="by" />
          </Typography>
          <Box sx={{ display: 'flex', width: 120, height: 13 }}>
            {isDarkBG ? <TyLogo fullWhite /> : <TyLogo full />}
          </Box>
        </Stack>
      </>
    </Box>
  );
}
