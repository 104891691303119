import type { RouteObject } from 'react-router-dom';

import ErrorPage from './error-page';
import Unsubscribe from './unsubscribe';

import Survey from './survey';

export const routes: RouteObject[] = [
  {
    path: '/:organizationId/:entityId/:surveyId/',
    element: <Survey />,
    errorElement: <ErrorPage />,
    loader: Survey.loader,
  },
  {
    path: '/unsubscribe/:distributionId',
    element: <Unsubscribe />,
  },
];
