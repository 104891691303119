import { Box, CardMedia } from '@trustyou/ui';

import { MAX_HEADER_IMAGE_HEIGHT_PX } from '../constants';
import type { SurveyHeaderProps } from '../static-elements';

export function CustomCardImage({ image }: SurveyHeaderProps) {
  switch (image?.variant) {
    case 'banner':
      return (
        <CardMedia
          image={image.url}
          component="img"
          alt="Survey banner image"
          height={MAX_HEADER_IMAGE_HEIGHT_PX}
          sx={{
            width: '100%',
            maxHeight: MAX_HEADER_IMAGE_HEIGHT_PX,
            objectFit: 'cover',
          }}
        />
      );
    case 'logo':
      return (
        <Box sx={{ height: MAX_HEADER_IMAGE_HEIGHT_PX, display: 'flex', alignItems: 'center' }}>
          <CardMedia
            image={image.url}
            component="img"
            alt="Survey logo image"
            sx={{
              maxHeight: MAX_HEADER_IMAGE_HEIGHT_PX / 2,
              objectFit: 'scale-down',
            }}
          />
        </Box>
      );
    default:
      return null;
  }
}
