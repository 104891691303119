import { useEffect } from 'react';

import { findFont } from '@trustyou/survey-manager';
import { darken, lighten } from '@trustyou/ui';

import type { Theme } from '../../client';
import { type ExtendedTheme, useThemeStore } from '../../store';

export const useSurveyTheme = ({ colors, fonts }: Theme) => {
  const { theme, setTheme } = useThemeStore();

  const surveyTheme: ExtendedTheme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: colors.primary,
        light: lighten(colors.primary, 0.1),
        dark: darken(colors.primary, 0.1),
        contrastText: colors.button_text,
        // contrastText: theme.palette.getContrastText(colors.primary),
      },
      text: {
        ...theme.palette.text,
        primary: colors.primary_text,
        secondary: colors.secondary_text,
      },
      background: {
        ...theme.palette.background,
        default: colors.background,
      },
    },
    components: {
      ...theme.components,
      MuiTypography: {
        ...theme.components?.MuiTypography,
        styleOverrides: {
          root: {
            whiteSpace: 'pre-wrap',
          },
        },
      },
    },
    typography: {
      ...theme.typography,
      fontFamily: findFont(fonts.body_text),
    },
    extra: {
      primaryColor: colors.primary,
      buttonTextColor: colors.button_text,
      entityNameColor: colors.entity_name,
      sectionTitleColor: colors.section_title,
      sectionTitleFontFamily: findFont(fonts.section_title),
    },
  };

  useEffect(() => {
    setTheme(surveyTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { surveyTheme };
};
