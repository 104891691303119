import { FormattedMessage } from 'react-intl';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import {
  Alert,
  Card,
  Stack,
  Typography,
  notFoundLostInSpace,
  personHoldingACoffeeCup,
} from '@trustyou/ui';
import type { AxiosError } from 'axios';

export default function ErrorPage() {
  const error = useRouteError();
  let errorMessage: string;
  let errorStatus: number | undefined = undefined;

  if (isRouteErrorResponse(error)) {
    errorMessage = error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
    if ((error as AxiosError)?.response?.status === 409) {
      errorStatus = 409;
    }
  } else if (typeof error === 'string') {
    errorMessage = error;
  } else {
    console.error(error);
    errorMessage = 'Unknown error';
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {errorStatus && errorStatus === 409 ? (
        <Stack sx={{ height: '100dvh', alignItems: 'center', justifyContent: 'center' }}>
          <Card sx={{ paddingInline: 50, paddingBlock: 15 }}>
            <Stack sx={{ alignItems: 'center' }}>
              <img
                src={personHoldingACoffeeCup}
                alt="Person holding coffee cup with status code 409"
                height="250"
                width="250"
              />
            </Stack>
            <Stack spacing={1} sx={{ alignItems: 'center' }}>
              <Typography variant="h6">
                <FormattedMessage
                  id="feedback-submission.already-submit.error.title"
                  defaultMessage={'Survey completed'}
                />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage
                  id="feedback-submission.already-submit.error.description"
                  defaultMessage="It looks like you've already taken this survey. Thank you for your input!"
                />
              </Typography>
            </Stack>
          </Card>
        </Stack>
      ) : (
        <Stack
          spacing={2}
          sx={{
            height: '100dvh',
            maxWidth: 360,
            margin: 'auto',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <img
            src={notFoundLostInSpace}
            alt="Dog lost in space with status code 404"
            height="250"
            width="250"
          />
          <Typography variant="h6" gutterBottom>
            <FormattedMessage
              id="survey.feedback.not-found.title"
              defaultMessage="Survey not found"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="survey.feedback.not-found.description"
              defaultMessage="We can't find the survey you're looking for."
            />
          </Typography>
          <Alert severity="error" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Stack>
      )}
    </>
  );
}
