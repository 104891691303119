import { isBoolean, isNumber, isString } from 'lodash';

// Utility functions to easily check type-specific arrays using TypeScript predicates.
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const isStringArray = (values: unknown): values is string[] =>
  Array.isArray(values) && values.every(isString);
export const isNumberArray = (values: unknown): values is number[] =>
  Array.isArray(values) && values.every(isNumber);
export const isBooleanArray = (values: unknown): values is boolean[] =>
  Array.isArray(values) && values.every(isBoolean);
