import { LANGUAGE_OPTIONS, messages as surveyMessages } from '@trustyou/survey-manager';

import type { Language } from '../client';

export function getLanguageOptions(languageList: Language[]) {
  return LANGUAGE_OPTIONS.filter((option) => languageList.includes(option.value));
}

export function findLocale(shortLanguageCode: string) {
  return Object.keys(surveyMessages).find((languageCode) =>
    languageCode.startsWith(shortLanguageCode)
  );
}
