import { useEffect, useRef } from 'react';
import type { UseFormResetField, UseFormWatch } from 'react-hook-form';

import type { Question } from '../../client';
import type { FormValues } from '../../survey';
import type { Content } from '../../types';
import {
  isMultipleSelect,
  isQuestion,
  isSection,
  isSingleSelect,
} from '../../utils/type-narrowing';

export function hasTriggers(question: Question) {
  return Boolean(question.triggers?.length);
}

export function useConditionalQuestions({
  contentItems,
  watch,
  resetField,
}: {
  contentItems: Content[];
  watch: UseFormWatch<FormValues>;
  resetField: UseFormResetField<FormValues>;
}) {
  const watched = watch();
  const fieldsToResetRef = useRef<Set<string>>(new Set());

  //This useEffect is used to reset the fields values that are not visible anymore especially triggers.
  useEffect(() => {
    fieldsToResetRef.current.forEach((fieldId) => {
      resetField(fieldId);
    });
    fieldsToResetRef.current.clear();
  });

  function hasActiveTriggers(question: Question) {
    const triggers = question.triggers;

    // FIXME: Remove this hardcoded additional concatenated trigger when backend colleagues provide real surveys with conditional questions that have multiple triggers.
    // Pointing to staging: <http://127.0.0.1:4202/survey/a9c0f083-feba-4604-b6e3-49e52ab20d8b/22ad72bc-86e5-468b-b3be-ad59b656522b/8765b9d7-eec5-4c8e-8469-7bb73d622b3f>
    // if (question.id === '4da744e6-0859-4577-bd26-3547432e1488') {
    //   triggers?.push({
    //     question_id: '337f914b-25b3-484f-bd45-b56b4a0fcbcd',
    //     values: [1, 2, 3],
    //   });
    // }

    // A conditional question can have multiple triggers.
    const parentQuestionIds = triggers?.map((t) => t.question_id) ?? [];

    const watchedParentQuestions = parentQuestionIds.reduce<
      Record<string, string | number | boolean | string[] | number[]>
    >((obj, id) => {
      const watchedValue = watched[id];
      if (watchedValue !== null) {
        return { ...obj, [id]: watchedValue };
      }
      return obj;
    }, {});

    for (const id in watchedParentQuestions) {
      const watchedValue = watchedParentQuestions[id];
      const triggerValues = triggers?.find((t) => t.question_id === id)?.values ?? [];
      if (isSingleSelect(watchedValue)) {
        return triggerValues.includes(watchedValue);
      }
      if (isMultipleSelect(watchedValue)) {
        // @ts-expect-error Type 'string' is not assignable to type 'never'.
        return triggerValues.some((v) => watchedValue.includes(v));
      }
    }

    return false;
  }

  function shouldRender(content: Content) {
    // Render anything that is not a question or a section.
    if (!isQuestion(content) && !isSection(content)) return true;

    // Render the section title only when it has, at least, one visible content item.
    if (isSection(content)) {
      const section = content;
      const sectionIndex = contentItems.findIndex((item) => item.id === section.id);
      for (let i = sectionIndex + 1; i < contentItems.length && !isSection(contentItems[i]); i++) {
        if (shouldRender(contentItems[i])) {
          return true;
        }
      }
      return false;
    }

    // Render all questions without triggers and conditional questions with active triggers.
    const question = content;
    const shouldRenderQuestion =
      !hasTriggers(question) || (hasTriggers(question) && hasActiveTriggers(question));

    if (!shouldRenderQuestion) {
      fieldsToResetRef.current.add(question.id);
    }

    return shouldRenderQuestion;
  }

  return { shouldRender };
}
