import { useIntl } from 'react-intl';

import { type Schema, array, boolean, mixed, number, object, string } from 'yup';

import {
  isEmail,
  isMaxLength,
  isMaxValue,
  isMinLength,
  isMinValue, // isPhoneNumber,
} from './type-narrowing';

import type { Question } from '../client';

export function useValidationSchema(questions: Question[]) {
  const intl = useIntl();
  const requiredFieldError = intl.formatMessage({
    id: 'survey.question.text.required-field-error.helper-text',
    defaultMessage: 'This field is required',
  });

  function getQuestionRules(question: Question) {
    const isMandatory = question.mandatory;
    const minLength = question.primitive.validations?.find(isMinLength)?.value ?? 0;
    const maxLength = question.primitive.validations?.find(isMaxLength)?.value ?? 255;
    const minValue = question.primitive.validations?.find(isMinValue)?.value;
    const maxValue = question.primitive.validations?.find(isMaxValue)?.value;
    const isEmailQuestion = question.primitive.validations?.find(isEmail)?.value;
    // const isPhoneNumberQuestion = question.primitive.validations?.find(isPhoneNumber)?.value;
    const notApplicable = question.use_not_applicable;

    const triggers = question.triggers?.reduce<
      Record<string, string | number | boolean | (string | number | boolean)[]>
    >((obj, { question_id, values }) => ({ ...obj, [question_id]: values }), {});
    const triggerQuestionIds = Object.keys(triggers ?? {});
    const hasTriggers = triggerQuestionIds.length > 0;

    if (notApplicable) {
      let textSchema = string();
      if (isMandatory) {
        textSchema = textSchema.required();
      }
      return textSchema;
    }

    switch (question.primitive.type) {
      case 'text': {
        // TODO: Add default text errors with translations and avoid having the ugly question ID in the error message.
        let textSchema = string();
        if (minLength) textSchema = textSchema.min(minLength);
        if (maxLength) textSchema = textSchema.max(maxLength);
        if (isEmailQuestion) textSchema = textSchema.email();
        // TODO: Refine the phone number validation. <https://stackoverflow.com/a/53210158/14873073>
        // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
        // if (isPhoneNumberQuestion) textSchema = textSchema.matches(phoneRegExp, 'Phone number is not valid');
        if (isMandatory && !hasTriggers) textSchema = textSchema.required(requiredFieldError);
        return textSchema;
        // FIXME: Apply the `.required()` validation only if any of its triggers is active. Apply this logic to all question types. https://jira.trustyou.com/browse/RE-550 (WIP: console error "Max call stack exceeded in yup library")
        // return textSchema.when(triggerQuestionIds, {
        //   is: (triggerQuestionValues: unknown[]) => isMandatory && triggerQuestionIds.some((id) => triggerQuestionValues?.includes(triggers?.[id])),
        //   then: () => textSchema.required(requiredFieldError),
        //   otherwise: () => textSchema,
        // });
      }
      case 'number':
      case 'scale':
      case 'nps': {
        let numberSchema = number();
        if (minValue) numberSchema = numberSchema.min(minValue as number);
        if (maxValue) numberSchema = numberSchema.max(maxValue as number);
        if (isMandatory && !hasTriggers) numberSchema = numberSchema.required(requiredFieldError);
        return numberSchema;
      }
      case 'date': {
        // TODO: Do proper date validation. Discuss with backend colleagues if we can pass this value as `date` (JSON supports it).
        // let dateSchema = date();
        // if (minValue) dateSchema = dateSchema.min(minValue as string);
        // if (maxValue) dateSchema = dateSchema.max(maxValue as string);
        let dateSchema = string();
        if (isMandatory && !hasTriggers) dateSchema = dateSchema.required(requiredFieldError);
        return dateSchema;
      }
      case 'boolean': {
        let booleanSchema = boolean();
        if (isMandatory && !hasTriggers) booleanSchema = booleanSchema.required(requiredFieldError);
        return booleanSchema;
      }
      case 'select': {
        let arraySchema = array();
        if (minLength) arraySchema = arraySchema.min(minLength as number);
        if (maxLength) arraySchema = arraySchema.max(maxLength as number);
        if (isMandatory && !hasTriggers) arraySchema = arraySchema.required(requiredFieldError);
        return arraySchema;
      }
      default:
        return mixed().nullable();
    }
  }

  const dynamicSchema = questions.reduce<Record<string, Schema>>((schema, question) => {
    schema[question.id] = getQuestionRules(question);
    return schema;
  }, {});

  return object().shape(dynamicSchema);
}
