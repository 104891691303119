import { useState } from 'react';
import { useIntl } from 'react-intl';

import { NativeSelect, OutlinedInput } from '@mui/material';
import { FormControl } from '@trustyou/ui';

import type { Language } from '../../../../client';

export type LanguageOption = {
  value: Language;
  label: string;
};

export type LanguageSelectorProps = {
  languageOptions: LanguageOption[];
  defaultLanguage?: Language;
  onChange?: (locale: Language) => void;
};

export const SurveyLanguageSelector = ({
  languageOptions,
  defaultLanguage = 'en',
  onChange = () => {},
}: LanguageSelectorProps) => {
  const intl = useIntl();
  const [language, setLanguage] = useState(defaultLanguage);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(event.target.value as Language);
    onChange(event.target.value as Language);
  };

  const ariaLabel = intl.formatMessage({
    id: 'survey.language-selector.label',
    defaultMessage: 'Language',
  });

  return (
    <FormControl>
      <NativeSelect
        value={language}
        onChange={handleChange}
        input={<OutlinedInput size="small" inputProps={{ 'aria-label': ariaLabel }} />}
        sx={{ width: 180, backgroundColor: 'common.white' }}
      >
        {languageOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};
