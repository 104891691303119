import type { FieldValues, UseFormTrigger } from 'react-hook-form';

import { Box, Stack, Typography } from '@trustyou/ui';

import { CustomCard } from '../../components/custom-card';
import { CustomCardImage } from '../../components/custom-card-image';
import { SurveyPagination } from '../survey-pagination/survey-pagination';

export type SurveyHeaderProps = {
  image?: {
    url: string;
    variant: 'banner' | 'logo';
  };
  entity?: {
    name: string;
    color?: string;
  };
  pagination?: {
    initialPage?: number;
    pageCount: number;
    showProgressBar?: boolean;
  };
  trigger?: UseFormTrigger<FieldValues>;
};

export function SurveyHeader({ image, entity, pagination, trigger }: SurveyHeaderProps) {
  const img = new Image();
  img.src = image?.url ?? '';

  return (
    <CustomCard>
      <>
        <Box sx={{ marginInline: -2, marginBlockStart: -3 }}>
          {image && (
            <CustomCardImage
              image={{
                url: image.url,
                variant: image.variant,
              }}
            />
          )}
        </Box>
        <Stack spacing={2} sx={{ textAlign: 'center' }}>
          {entity && (
            <Typography component="h1" variant="h6" color={entity.color}>
              {entity.name}
            </Typography>
          )}
          {pagination && pagination.pageCount > 1 && (
            <SurveyPagination {...pagination} showProgressBar trigger={trigger} />
          )}
        </Stack>
      </>
    </CustomCard>
  );
}
