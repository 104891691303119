import { FormattedMessage } from 'react-intl';

import { LoadingButton } from '@mui/lab';

type SurveySubmitButtonProps = { loading: boolean };

export const SurveySubmitButton = ({ loading }: SurveySubmitButtonProps) => {
  return (
    <LoadingButton
      type="submit"
      variant="contained"
      size="large"
      loading={loading}
      // loadingPosition="end"
      sx={{ width: '100%', maxWidth: 384, alignSelf: 'center' }}
    >
      <FormattedMessage id="survey.submit.button" defaultMessage="Submit" />
    </LoadingButton>
  );
};
