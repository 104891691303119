import { useEffect } from 'react';
import type { FieldValues, UseFormTrigger } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useAlertStore } from '@trustyou/shared';
import { Button, LinearProgress, Stack, Typography, alpha } from '@trustyou/ui';

import { useFeedbackPaginationStore } from '../../../../store/feedback-pagination-store';
import { MAX_CARD_WIDTH_PX } from '../../constants';

export type SurveyPaginationProps = {
  pageCount: number;
  initialPage?: number;
  showProgressBar?: boolean;
  trigger?: UseFormTrigger<FieldValues>;
};

export function SurveyPagination({
  pageCount,
  initialPage = 0,
  showProgressBar = false,
  trigger,
}: SurveyPaginationProps) {
  const { currentPage, setCurrentPage } = useFeedbackPaginationStore();
  const naturalPage = currentPage + 1;
  const progress = (naturalPage / pageCount) * 100;
  const isFirstPage = naturalPage === 1;
  const isLastPage = naturalPage === pageCount;
  const { alert } = useAlertStore();

  useEffect(() => {
    setCurrentPage(initialPage);
    // Set initial page on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToNextPage = () => {
    const onfulfilled = (value: boolean) => {
      if (value) setCurrentPage(currentPage + 1);
      else {
        console.error('onfulfilled', value);
        alert.error('Check the questions with warnings.');
      }
    };
    const onrejected = (reason: unknown) => console.error('onrejected', reason);
    trigger?.().then(onfulfilled, onrejected);
    window.scroll({ top: 0 });
  };

  const navigateToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
    window.scroll({ top: 0 });
  };

  if (currentPage === pageCount) return null;

  return (
    <Stack spacing={0.5} sx={{ maxWidth: MAX_CARD_WIDTH_PX }}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          variant={showProgressBar || (!showProgressBar && isLastPage) ? 'text' : 'contained'}
          sx={{
            visibility: isFirstPage ? 'hidden' : 'visible',
            ...(!showProgressBar && {
              backgroundColor: 'common.white',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.common.white, 0.9),
                color: 'primary.main',
              },
            }),
            minWidth: 0,
          }}
          onClick={navigateToPreviousPage}
        >
          <FormattedMessage id="survey.common.back" defaultMessage="Back" />
        </Button>
        {showProgressBar && (
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage
              id="survey.pagination.page"
              defaultMessage="Page {naturalPage} of {pageCount}"
              values={{ naturalPage, pageCount }}
            />
          </Typography>
        )}
        <Button
          variant={showProgressBar ? 'text' : 'contained'}
          sx={{
            visibility: isLastPage ? 'hidden' : 'visible',
            ...(!showProgressBar && {
              backgroundColor: 'common.white',
              color: 'primary.main',
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.common.white, 0.9),
                color: 'primary.main',
              },
            }),
            minWidth: 0,
          }}
          onClick={navigateToNextPage}
        >
          <FormattedMessage id="survey.common.next" defaultMessage="Next" />
        </Button>
      </Stack>
      {showProgressBar && (
        <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: 1 }} />
      )}
    </Stack>
  );
}
